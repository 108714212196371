import { MenuItem, Select } from "@mui/material";
import { CARRIER_REQ_PARAMS_OPT } from "constants/AppConstants";
import { APP_COLOR } from "constants/Color";
import MDBox from "lib/components/MDBox";

const RequiredParamsDrop = ({style = {}}) => {

    const onDropdownChange = () => {

    }

    return(
        <MDBox width="100%"  style={{...style}}>
            <Select
                label=""
                sx={{
                width: "148px",
                height: "36px",
                "& .MuiSvgIcon-root": {
                    color: `${APP_COLOR.APP_SECONDARY_COLOR}`,
                    display: "inline-block",
                    fontSize:'18px!important'
                }
                }}
                SelectDisplayProps={{
                style:{
                    color: `${APP_COLOR.APP_SECONDARY_COLOR}`}
                }}
                onChange={onDropdownChange}
            >
            {CARRIER_REQ_PARAMS_OPT.map((item) => (
              <MenuItem
                style={{ color: `${APP_COLOR.APP_SECONDARY_COLOR}`, }}
                key={item.key}
                value={item.value}
              >
                {item.value}
              </MenuItem>
            ))}
            </Select>            
        </MDBox>
    )
}
export default RequiredParamsDrop;