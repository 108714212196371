import { Delete } from "@mui/icons-material";
import { Typography } from "@mui/material";
import RequiredParamsDrop from "components/Carrier/RequiredParamsDrop";
import useDebouncedWatch from "hooks/use-debounce-watch";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { useState } from "react";

const KeyValue = ({item,onDelete,onChange,showDelete = true,canEdit=true,required=false,children}) => {

    const [value,setValue] = useState(item);

    useDebouncedWatch(() => {
        onChange(value);
    },[value]);

    return (
        <MDBox display="flex" width="100%" flexDirection="column">
            <MDBox width="100%" display="flex" justifyContent="flex-start">
                <MDBox width="50%!important" padding="4px">
                    <MDInput fullWidth required={required}  type="text" label={"Key"} value={value.key} onChange={(e) => {
                        setValue(prev => {
                            return{
                                ...prev,
                                key : e.target.value
                            }
                        })
                    }} />
                </MDBox>
                <MDBox width="50%!important" padding="4px" display="flex" justifyContent="center" alignItems="center">
                    <MDInput fullWidth  type="text" label={"Value"} value={value.value} onChange={canEdit ? (e) => {
                        setValue(prev => {
                            return{
                                ...prev,
                                value : e.target.value
                            }
                        })
                    } : () => {} }/>
                    {<RequiredParamsDrop style={{height: '28px'}} />}
                    {showDelete && <Delete onClick={onDelete} sx={{cursor :'pointer'}}/>}   
                </MDBox>
            </MDBox>            
        </MDBox>
    )
}
export default KeyValue;