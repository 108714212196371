import { Typography } from "@mui/material";
import KeyValue from "components/CommonUI/KeyValue";
import { CARRIER_REQ_PARAMS } from "constants/AppConstants";
import { CARRIER_INS } from "constants/AppConstants";
import MDBox from "lib/components/MDBox";
import MDInput from "lib/components/MDInput";
import { memo } from "react";

const InboundConfig = () => {
    return(
        <MDBox>
            <MDBox display="flex" justifyContent="center">
                <Typography variant="h5" m={2}>{"Inbound configurations"}</Typography>
            </MDBox>

            <MDBox margin={'8px 8px 12px 8px'} display="flex" flexDirection="column" justifyContent="flex-start">
                <MDBox width="100%!important" display="flex" flexDirection="column">
                    <MDInput disabled={true} fullWidth type="text" label="Webhook" value={'https://prod.bliqmsg.com/carrier/inbound/telegram'} />
                    <Typography mt={1} fontSize={"14px"} fontWeight={600}>{CARRIER_INS.WEBHOOK_INS}</Typography>
                </MDBox>   
                <MDBox display="flex" width="100%" flexDirection="column" mt={2}>
                    <MDBox display="flex" flexDirection="column" sx={{height : '0px'}} width="100%" justifyContent="flex-end">
                        {/* <Button onClick={addMoreParams} startIcon={<Add/>}>{"Add more"}</Button> */}
                    </MDBox>
                    {CARRIER_REQ_PARAMS.map((itm,indx) => <KeyValue required={true} canEdit={false} showDelete={false} item={itm} onChange={() => {}} onDelete={() => {} } key={"param_" + itm.index}/>)}
                </MDBox>                                    
            </MDBox>
        </MDBox>
    )
}
export default memo(InboundConfig);