import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import MDBox from "lib/components/MDBox"
import MDInput from "lib/components/MDInput"
import { memo, useState } from "react"
import OutboundRequestConfig from "./OutboundRequestConfig"
import { Refresh } from "@mui/icons-material"

const OutboundConfig = () => {

    const [carrierData, setCarrierData] = useState({
        carrier_name : '',
        http_method : 'POST',
        encoding_type : 'DEFAULT',
        content_type : 'application/json',
        max_length : 160,
        auth_val : '',
        send_url : '',
        request_body : 'text={{text}}&from={{from}}&to={{to}}',
        example_request_body : '',
        response_body : '',
        limit : 0
    }) 

    const onHttpMethodChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                http_method : e.target.value
            }
        })
    }
    const onEncodingTypeChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                encoding_type : e.target.value
            }
        })
    }
    const onContentTypeChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                content_type : e.target.value
            }
        })
    }
    const onMaxLengthChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                max_length : e.target.value
            }
        })
    }
    const onAuthChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                auth_val : e.target.value
            }
        })  
    }

    const onSendUrlChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                send_url : e.target.value
            }
        }) 
    }

    const onRequestBodyChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                request_body : e.target.value
            }
        }) 
    }

    const onExampleRequestBodyChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                example_request_body : e.target.value
            }
        }) 
    }

    const onResponseBodyChange = (e) => {
        setCarrierData(prev => {
            return {
                ...prev,
                response_body : e.target.value
            }
        }) 
    }

    return(
        <MDBox>
            <MDBox display="flex" justifyContent="center">
                <Typography variant="h5" m={2}>{"Outbound configurations"}</Typography>
            </MDBox>

            <MDBox margin={'8px 8px 12px 8px'} display="flex" flexDirection="row" justifyContent="flex-start">
                <MDBox>
                    <FormControl fullWidth>
                        <InputLabel id="http-method">Http Methods</InputLabel>
                        <Select
                            labelId="http-method"
                            id="http-method-select"
                            value={carrierData.http_method}
                            sx={{
                                height:'42px',
                                marginRight:'8px'
                            }}
                            label="HTTP Methods"
                            onChange={onHttpMethodChange}
                        >
                            <MenuItem value={"POST"}>HTTP POST</MenuItem>
                            <MenuItem value={"PUT"}>HTTP PUT</MenuItem>
                            <MenuItem value={"GET"}>HTTP GET</MenuItem>
                        </Select>
                    </FormControl>
                </MDBox>
                <MDBox width="100%!important">
                    <MDInput  fullWidth onChange={onSendUrlChange} type="text" label="Send URL" value={carrierData.send_url} />
                </MDBox>                   
            </MDBox>

            <MDBox margin={'12px 0px 12px 0px'} width="100%">
                <OutboundRequestConfig/>                
            </MDBox>                

            <MDBox margin={'8px'}>
                <MDBox sx={{position : 'relative'}}>
                    <MDInput multiline minRows={4} sx={{ marginRight:'8px'}}  fullWidth onChange={onResponseBodyChange} type="" label="MT Response check" value={carrierData.response_body} />
                    <Button sx={{position : 'absolute',bottom : 0, right : 0}}  startIcon={<Refresh/>}>{"Fetch response"}</Button>            
                 </MDBox>   
                <Typography margin={'8px'} fontSize={'12px'}>The content that must be in the response to consider the request successful</Typography>
            </MDBox> 
        </MDBox>
    )
}
export default memo(OutboundConfig);