import { Add } from "@mui/icons-material";
import { Button, Tab, Tabs } from "@mui/material";
import KeyValue from "components/CommonUI/KeyValue";
import MDBox from "lib/components/MDBox";
import { useCallback, useState } from "react";
import AuthParams from "./AuthParams";
import RequiredParamsDrop from "../RequiredParamsDrop";

const TAB = {
    auth : 'auth',
    headers : 'headers',
    params : 'params',
    body : 'body'
}
const OutboundRequestConfig = () => {

    const [currentTab,setCurrentTab] = useState(TAB.headers);
    
    const [keyValue, setKeyValue] = useState({
        headers : [{type : TAB.headers,key : '',value : '',index : 0}],
        auth : [{type : TAB.auth,key : '',value : '',index : 0}],
        params : [{type : TAB.params,key : '',value : '',index : 0}],
        body : [{type : TAB.body,key : '',value : '',index : 0}]
    })

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };    

    const deleteHeader = useCallback((itm) => {
        if(keyValue.headers.length === 1){
            return;
        }
        const newHeaders = keyValue.headers.filter((item) => itm.index !== item.index);
        setKeyValue(prev => {
            return{
                ...prev,
                headers : newHeaders
            }
        })
    },[keyValue.headers])

    const deleteParams = useCallback((itm) => {
        if(keyValue.headers.length === 1){
            return;
        }
        const newHeaders = keyValue.params.filter((item) => itm.index !== item.index);
        setKeyValue(prev => {
            return{
                ...prev,
                params : newHeaders
            }
        })
    },[keyValue.params])

    const deleteBody = useCallback((itm) => {
        if(keyValue.body.length === 1){
            return;
        }
        const newHeaders = keyValue.body.filter((item) => itm.index !== item.index);
        setKeyValue(prev => {
            return{
                ...prev,
                body : newHeaders
            }
        })
    },[keyValue.body])

    const updateKeyValue = useCallback((value) => {

        if(value.type === TAB.headers) {
            const t = keyValue.headers.map(itm => {
                if(itm.index === value.index){
                    return value;
                }
                else return itm;
            });
            setKeyValue(prev => {
                return{
                    ...prev,
                    headers : t
                }
            });
        }   
        else if(value.type === TAB.auth){
            const t = keyValue.auth.map(itm => {
                if(itm.index === value.index){
                    return value;
                }
                else return itm;
            });
            setKeyValue(prev => {
                return{
                    ...prev,
                    auth : t
                }
            });
        }       
        else if(value.type === TAB.params){
            const t = keyValue.params.map(itm => {
                if(itm.index === value.index){
                    return value;
                }
                else return itm;
            });
            setKeyValue(prev => {
                return{
                    ...prev,
                    params : t
                }
            });
        }       
        else if(value.type === TAB.body){
            const t = keyValue.body.map(itm => {
                if(itm.index === value.index){
                    return value;
                }
                else return itm;
            });
            setKeyValue(prev => {
                return{
                    ...prev,
                    body : t
                }
            });
        }       
    },[keyValue,setKeyValue]) 

    const addMoreHeaders = useCallback(() => {
        const newHeader = {type : 'header',key : '',value : '',index : keyValue.headers.length} 
        setKeyValue(prev => {
            return{
                ...prev,
                headers : [...prev.headers,newHeader]
            }
        })
    },[keyValue.headers]);

    const addMoreBody = useCallback(() => {
        const newBody = {type : 'body',key : '',value : '',index : keyValue.body.length} 
        setKeyValue(prev => {
            return{
                ...prev,
                body : [...prev.body,newBody]
            }
        })
    },[keyValue.body]);

    const addMoreParams = useCallback(() => {
        const newParams = {type : 'params',key : '',value : '',index : keyValue.params.length} 
        setKeyValue(prev => {
            return{
                ...prev,
                headers : [...prev.params,newParams]
            }
        })
    },[keyValue.params]);

    return(
        <>
            <MDBox sx={{ width: '100%' }}>
                <Tabs
                    value={currentTab}
                    onChange={handleChange}
                    textColor="secondary"
                    aria-label="secondary tabs example"
                >
                    <Tab value={TAB.headers} label="Headers" />
                    <Tab value={TAB.auth} label="Auth" />
                    <Tab value={TAB.params} label="Params" />
                    <Tab value={TAB.body} label="Body" />
                </Tabs>
                {currentTab === TAB.auth &&
                    <MDBox display="flex" width="100%" flexDirection="column">
                        <AuthParams/>   
                    </MDBox>                
                }
                {currentTab === TAB.headers && 
                    <MDBox display="flex" width="100%" flexDirection="column">
                        <MDBox display="flex" sx={{height : '28px'}} width="100%" justifyContent="flex-end">
                            <Button onClick={addMoreHeaders} startIcon={<Add/>}>{"Add more"}</Button>
                        </MDBox>
                        {keyValue.headers.map((itm,indx) => 
                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                <KeyValue item={itm} onChange={updateKeyValue} onDelete={() => deleteHeader(itm)} key={"header_" + itm.index}/>
                            </MDBox>)
                        }
                    </MDBox>
                }
                {currentTab === TAB.params && 
                    <MDBox display="flex" width="100%" flexDirection="column">
                        <MDBox display="flex" sx={{height : '28px'}} width="100%" justifyContent="flex-end">
                            <Button onClick={addMoreParams} startIcon={<Add/>}>{"Add more"}</Button>
                        </MDBox>
                    {keyValue.params.map((itm,indx) => <KeyValue item={itm} onChange={updateKeyValue} onDelete={() => deleteParams(itm)} key={"param_" + itm.index}/>)}
                    </MDBox>                
                }
                {currentTab === TAB.body && 
                    <MDBox display="flex" width="100%" flexDirection="column">
                        <MDBox display="flex" sx={{height : '28px'}} width="100%" justifyContent="flex-end">
                            <Button onClick={addMoreBody} startIcon={<Add/>}>{"Add more"}</Button>
                        </MDBox>
                        {keyValue.body.map((itm,indx) => <KeyValue item={itm} onChange={updateKeyValue} onDelete={() => deleteBody(itm)} key={"body_" + itm.index}/>)}
                    </MDBox>                
                }
            </MDBox>                      
        </>
    )
}
export default OutboundRequestConfig;